<template>
  <div class="page">
    <div class="c-emailsent c-emailsent_h-800">
      <div class="c-emailsent__body c-emailsent__body_top-208">
        <img src = "@/assets/images/email-logo.svg" alt="email-check">
        <h2 class="c-emailsent__title"><span class="c-emailsent__title_black">Email</span> Sent</h2>
        <p class="c-emailsent__content c-emailsent__content-forgotpwd">If a Sparrow account exists for <span id = "requestEmail" class="c-emailsent__content-bold">abc@abc.com,</span> an e-mail will be sent with further instructions.</p>
        <a class="c-emailsent__link" @click='routerRedirect("/signin")'>Return to Home >></a>
      </div>
    </div>
  </div>
</template>
<script>
import $ from 'jquery'
export default {
  name: 'EmailSent',
  components: {
  },
  data() {
    return {
    }
  },
  mounted:function(){
    if(localStorage.getItem('email')!=''){
      $('#requestEmail').text(localStorage.getItem('email'));
    }
  }
}
</script>
